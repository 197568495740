$card-width-compact: min(240px, calc(100% - 48px));
$card-width-wide: 240px;
$filters-container-width: 240px;
$main-container-width: calc(100vw - 240px - 120px);
$main-container-min-width: 240px;

#projectsPageContainer {
  display: flex;
  flex-direction: row;
  padding: 24px;
  width: calc(100vw - 48px);
  justify-content: space-between;
  align-items: flex-start;
}

#wideProjectsPageContainer {
  display: flex;
  flex-direction: row;
  padding: 24px;
  width: calc(100vw - 48px);
  align-items: flex-start;
}


#compactProjectsPageContainer {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: calc(max(calc(100vw - 48px), 300px));
  justify-content: flex-start;
  align-items: center;
}

.logoContainer {
  background-color: var(--background-inverse);
  width: $card-width-compact;
  height: 160px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (width > 600px) {
    width: $card-width-wide
  }
}

#compactResultsHeadingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

#compactFiltersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: calc(max($main-container-min-width, $main-container-width));
}

.detailsContainer {
  width: calc($card-width-compact - 2px - 24px);
  background-color: var(--text-inverse);
  border: 1px solid var(--text);
  border-color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  padding: 12px;
  color: var(--gray-text);

  @media (width > 600px) {
    width: calc($card-width-wide - 2px - 24px)
  }
}

.wideProjectContainer {
  width: 100%;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
}

.compactProjectContainer {
  width: 100%;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.compactProjectDetailsContainer {
  width: 100%;
  background-color: var(--text-inverse);
  border: 1px solid var(--text);
  border-color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  padding: 12px;
  color: var(--gray-text);

}

#wideProjectLinkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  width: 100%;
  justify-content: flex-start;
}

.wideProjectDetailsContainer {
  background-color: var(--text-inverse);
  border: 1px solid var(--text);
  border-color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  padding: 12px;
  color: var(--gray-text);
}

#languageFiltersContainer {
  display: flex;
  flex-direction: column;
  height: 400px;
}

.filtersDivider {
  height: 1px;
  width: auto;
  background-color: var(--text-secondary);
  margin: 32px 0;
}

#topicFiltersContainer {
  display: flex;
  flex-direction: column;
  height: 400px;
}

#languageFiltersScrollContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin: 16px 0;
}

#languageFiltersHeading {
  font-size: 0.8rem;
  color: var(--text-secondary);
}

.cardContainer {
  width: $card-width-compact;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width > 600px) {
    width: $card-width-wide
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}


.loadingCardContainer {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  background: linear-gradient(110deg, var(--opacity-1) 10%, var(--opacity-2) 20%, var(--opacity-1) 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;

  width: $card-width-compact;
  height: 160px;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width > 600px) {
    width: $card-width-wide
  }
}

#projectName {
  font-size: 1rem;
  margin: 4px 0;
  color: var(--text)
}

#projectOwner {
  font-size: 0.4rem;
}

#filtersContainer {
  display: flex;
  flex-direction: column;
  width: $filters-container-width
}

#filterByHeading {
  font-size: 1rem;
  color: var(--text);
}

#selectedTopicChip {
  background-color: var(--blue-background);
  color: var(--blue-text);
  width: auto;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 16px;
}

#compactSelectedTopicChip {
  background-color: var(--blue-background);
  color: var(--blue-text);
  width: auto;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 16px;
  margin-bottom: 16px;
}

#selectedLanguageChip {
  background-color: var(--violet-background);
  color: var(--violet-text);
  width: auto;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 16px;
}

#compactSelectedLanguageChip {
  background-color: var(--violet-background);
  color: var(--violet-text);
  width: auto;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-right: 16px;
  margin-bottom: 16px;
}

#closeIcon {
  font-size: 20px;
  margin-left: 8px;
  color: var(--red-text);
}

#filterIcon {
  font-size: 20px;
  margin-left: 8px;
  color: var(--text);
}

#selectedTopicsContainer {
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  flex-wrap: wrap;
}

#compactSelectedTopicsContainer {
  display: flex;
  flex-direction: row;
  margin: 16px 0;
  flex-wrap: wrap;
}

#resultsCountContainer {
  font-size: 1rem;
  color: var(--text);
}

#resultsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  width: calc(max($main-container-min-width, calc($main-container-width - 48px)));
}


#resultsContainerCompact {
  display: flex;
  flex-direction: column;
  width: calc(max($main-container-min-width, calc($main-container-width - 48px)));
}

#compactResultsContainer {
  display: flex;
  flex-direction: column;
}

#compactFilteredProjectsContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

#compactFilteredProjectLinkContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  width: 100%;
  justify-content: center;
}

#languageFilterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--text);
  font-size: 1rem;
  margin: 8px 0;
  cursor: pointer;

  div {
    margin: 0 4px;
  }
}

#filtersDialogContainer {
  background-color: var(--background);
  padding: 16px;
  color: var(--text);
}

#topicFilterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--text);
  font-size: 1rem;
  margin: 8px 0;
  cursor: pointer;

  div {
    margin: 0 4px;
  }
}

#selectedFiltersContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: var(--text);
  font-size: 1rem;
  margin: 8px 0;
  cursor: pointer;

  div {
    margin: 0 4px;
  }
}

#languageCheckbox {
  color: white;
}

#searchContainer {
  background-color: #fff;
  border-radius: 16px;
  font-family: "Space Grotesk";
  width: 100%;

  div, input {
    background-color: #fff;
    border: none;
    outline: none;
  }
}