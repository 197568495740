.main {
  background-color: var(--background-secondary);
  width: 100vw;
  overflow-y: scroll;
  padding-top: 100px;
  margin: 0;
  font-family: 'Space Grotesk';
}


html, body {
  margin: 0;
}

#homePage {
  width: 100vw;
}

