
.heroContainer {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.h1 {
  max-width: 70vw;
  font-size: 1.5rem;
  @media (width > 400px) {
    font-size: 2rem;
  }
  @media (width > 500px) {
    font-size: 3rem;
  }
  @media (width > 650px) {
    font-size: 4rem;
  }
  @media (width > 900px) {
    font-size: 5rem;
  }

  font-weight: bold;
  color: var(--text);
  white-space: nowrap;
  overflow: hidden;
}

.overText {
  &::before {
    position: relative;
    bottom: -0.1em;
    content: '';
    display: inline-block;
    width: 0.9em;
    height: 0.9em;
    background-color: var(--primary);
    line-height: 0;
    margin-right: 1em;
  }

  font-size: 0.5rem;
  @media (width > 400px) {
    font-size: 0.75rem;
  }
  @media (width > 500px) {
    font-size: 1rem;
  }
  @media (width > 600px) {
    font-size: 1.25rem;
  }


  letter-spacing: 0.02em;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--text);
  max-width: 70vw;
  text-align: center;
}

.description {
  font-size: 0.5rem;
  @media (width > 400px) {
    font-size: 0.75rem;
  }
  @media (width > 500px) {
    font-size: 1rem;
  }
  @media (width > 600px) {
    font-size: 1.25rem;
  }

  opacity: 0.8;
  max-width: 70vw;
  padding-top: 16px;
  @media (width > 1200px) {
    max-width: 685px;
    padding-top: 24px;
  }
  text-align: center;


  color: var(--text-secondary);

}