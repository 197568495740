@import "src/features/projects/projects.module";

$card-width-compact: min(240px, calc(100% - 48px));
$card-width-wide: 240px;
$filters-container-width: 240px;
$main-container-width: calc(100vw - 240px - 120px);

.popularContainer {
  background-color: var(--background-variant);
  width: calc(100vw - 32px);
  color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 24px 16px;
}

.h3 {
  font-size: 1rem;
  @media (width > 400px) {
    font-size: 1.5rem;
  }
  @media (width > 650px) {
    font-size: 2rem;
  }
  @media (width > 900px) {
    font-size: 2.5rem;
  }

  font-weight: bold;
  color: var(--text);
  white-space: nowrap;
  overflow: hidden;
}

#ctaButton {
  color: var(--text-inverse);
  background-color: var(--text);
  width: 100%;

  text-transform: none;
  border-radius: 0;
}

#ctaButtonLinkContainer {
  width: calc($card-width-compact - 48px);
  margin: 16px 0;

  @media (width > 600px) {
    width: $card-width-wide;
  }

}

.cardContainer {
  width: $card-width-compact;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (width > 600px) {
    width: $card-width-wide
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.logoContainer {
  background-color: var(--background-inverse);
  width: $card-width-compact;
  height: 160px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (width > 600px) {
    width: $card-width-wide
  }
}

.detailsContainer {
  width: calc($card-width-compact - 2px - 24px);
  background-color: var(--text-inverse);
  border: 1px solid var(--text);
  border-color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 0.75rem;
  padding: 12px;
  color: var(--gray-text);

  @media (width > 600px) {
    width: calc($card-width-wide - 2px - 24px)
  }
}

#projectLinkContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  width: 100%;
  justify-content: flex-start;
}
