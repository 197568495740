:root {
  --primary: #3984ff;
  --text: #f7f5f2;
  --text-inverse: #1e1919;
  --primary-inverse: #0061fe;
  --text-secondary: rgb(104, 112, 121);
  --text-variant: #ffffff;
  --background: rgb(29, 34, 39);
  --background-secondary: rgb(32, 40, 48);
  --background-variant: #1e1919;
  --background-cta: rgb(29, 34, 39);
  --border: rgba(241, 233, 231, 0.15);
  --border-light: rgba(241, 233, 231, 0.05);
  --background-inverse: #ffffff;
  --shadow: rgba(10, 10, 10, 0.15);
  --abs: #fff;

  --opacity-1: #FFFFFF;
  --opacity-2: #f7f5f2;

  --chip-text: #fff;
  --chip-border: #1e1919;
  --chip-background: #1e1919;

  --success-text: #b4dc19;
  --success-border: #b4dc19;
  --success-background: rgba(180, 220, 25, 0.3);

  --disabled-text: rgba(247, 245, 242, 0.3);
  --disabled-border: rgba(247, 245, 242, 0.3);
  --disabled-background: #d8d3cb;

  --gray-text: #9BA3AF;
  --gray-border: #9BA3AF;
  --gray-background: #282E36;

  --rose-text: #FB7185;
  --rose-border: #FB7185;
  --rose-background: #4B2C38;

  --pink-text: #F471B5;
  --pink-border: #F471B5;
  --pink-background: #4A2E43;

  --fuchsia-text: #BF6ACF;
  --fuchsia-border: #BF6ACF;
  --fuchsia-background: #452D54;

  --purple-text: #B57FEF;
  --purple-border: #B57FEF;
  --purple-background: #3B2F56;

  --violet-text: #9F84EC;
  --violet-border: #9F84EC;
  --violet-background: #343156;

  --indigo-text: #7B85EB;
  --indigo-border: #7B85EB;
  --indigo-background: #2D3255;

  --blue-text: #5FA4F9;
  --blue-border: #5FA4F9;
  --blue-background: #263856;

  --sky-text: #37BAF5;
  --sky-border: #37BAF5;
  --sky-background: #224053;

  --cyan-text: #2CADC4;
  --cyan-border: #2CADC4;
  --cyan-background: #244345;

  --teal-text: #2CD4BF;
  --teal-border: #2CD4BF;
  --teal-background: #22434F;

  --emerald-text: #35B286;
  --emerald-border: #35B286;
  --emerald-background: #24433F;

  --green-text: #49DE80;
  --green-border: #49DE80;
  --green-background: #254638;

  --lime-text: #9ADA37;
  --lime-border: #9ADA37;
  --lime-background: #34462F;

  --yellow-text: #F8CB15;
  --yellow-border: #F8CB15;
  --yellow-background: #48422E;

  --amber-text: #FBBE24;
  --amber-border: #FBBE24;
  --amber-background: #4B3D2E;

  --orange-text: #FB923C;
  --orange-border: #FB923C;
  --orange-background: #4B362E;

  --red-text: #F16F6F;
  --red-border: #F16F6F;
  --red-background: #4A2D33;
}

body.light-mode {
  --primary: #0061fe;
  --text: #1e1919;
  --text-inverse: #f7f5f2;
  --accent: #3984ff;
  --primary-inverse: #3984ff;
  --text-secondary: rgb(104, 112, 121);
  --background: rgb(255, 255, 255);
  --background-secondary: rgb(255, 255, 255);
  --background-variant: rgb(240, 240, 240);
  --background-cta: #1e1919;
  --text-variant: #1e1919;
  --border: rgba(241, 233, 231, 0.15);
  --border-light: rgba(241, 233, 231, 0.05);
  --background-inverse: #000000;
  --shadow: rgba(200, 200, 200, 0.15);
  --abs: #00;

  --opacity-1: #9B64001A;
  --opacity-2: #9B640033;

  --rose-text: #E11D47;
  --rose-border: #E11D47;
  --rose-background: #FEEAEC;

  --pink-text: #DA2877;
  --pink-border: #DA2877;
  --pink-background: #FCEDF6;

  --purple-text: #9333E9;
  --purple-border: #9333E9;
  --purple-background: #F6EEFF;

  --fuchsia-text: #C025D3;
  --fuchsia-border: #C025D3;
  --fuchsia-background: #FBEDFF;

  --violet-text: #7C3AED;
  --violet-border: #7C3AED;
  --violet-background: #F1EFFE;

  --indigo-text: #4F45E4;
  --indigo-border: #4F45E4;
  --indigo-background: #E8EDFE;

  --blue-text: #2463EB;
  --blue-border: #2463EB;
  --blue-background: #E4EFFE;

  --sky-text: #0084C7;
  --sky-border: #0084C7;
  --sky-background: #E7F5FE;

  --cyan-text: #0991B1;
  --cyan-border: #0991B1;
  --cyan-background: #D9FCF6;

  --teal-text: #0C9488;
  --teal-border: #0C9488;
  --teal-background: #DBFBFE;

  --emerald-text: #069668;
  --emerald-border: #069668;
  --emerald-background: #DDFBEB;

  --green-text: #16A349;
  --green-border: #16A349;
  --green-background: #E4FDED;

  --lime-text: #65A20C;
  --lime-border: #65A20C;
  --lime-background: #F1FCD9;

  --yellow-text: #CA8A03;
  --yellow-border: #CA8A03;
  --yellow-background: #FEFBD2;

  --amber-text: #D97707;
  --amber-border: #D97707;
  --amber-background: #FEF6D6;

  --orange-text: #EA580B;
  --orange-border: #EA580B;
  --orange-background: #FFF1E0;

  --red-text: #DC2625;
  --red-border: #DC2625;
  --red-background: #FEE9E9;
}
