#navBar {
  position: fixed;
  top: 0;
  height: 100px;
  background-color: transparent;
  backdrop-filter: blur(10px);
  padding: 0 16px;
  width: calc(100vw - 32px);
  box-shadow: rgba(241, 233, 231, 0.05) 0 1px 0 0;
  z-index: 10;
}

.navTitle {
  color: var(--text);
  font-size: 1.25rem;
  margin: 16px;

  @media (width < 600px) {
    display: none;
  }
}

.navButton {
  cursor: pointer;
  margin: 2px;
  color: var(--text)
}