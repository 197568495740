@import "src/features/projects/projects.module";

.missionContainer {
  padding: 24px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (width < 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  background-color: var(--background-cta);
  width: calc(100vw - 32px);
  color: var(--text-inverse);
}

.h3 {
  font-size: 1rem;
  width: 100%;
  font-weight: bold;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  margin-bottom: 16px;

  @media (width > 500px) {
    font-size: 1.5rem;
  }
  @media (width > 650px) {
    font-size: 2rem;
  }
  @media (width > 900px) {
    font-size: 2.5rem;
  }
  @media (width > 600px) {
    max-width: 80%;
    white-space: normal;
    overflow: auto;
    text-align: start;
  }


}

#rightArrowIcon {
  stroke: white;
  margin-left: 12px;
}

#ctaButtonContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
  white-space: nowrap;
  color: white;
  text-transform: none;
  width: 100%;
}

#ctaButton {
  width: 100%;
  background-color: var(--primary);
  border-radius: 0;
}

#ctaButtonLinkContainer {
  width: calc($card-width-compact - 48px);
}