.footerContainer {
  background-color: var(--background-inverse);
  width: calc(100vw - 32px);
  padding: 24px 16px;

  display: flex;
  flex-direction: column;

  @media (width > 650px) {
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
  }

  @media (width > 900px) {
    justify-content: space-around;
  }
}

.footerHeader {
  margin-bottom: 0.5rem;
  color: var(--background-variant);
  font-weight: bold;

  font-size: 1rem;
  @media (width > 400px) {
    font-size: 1.25rem;
  }
  @media (width > 650px) {
    font-size: 1.5rem;
  }
}

.footerSection {
  display: flex;
  flex-direction: column;
  margin: 24px 0;

  @media (width > 650px) {
    width: calc((100vw - 32px) / 4);
  }
}

.footerItem {
  color: var(--background-variant);
  text-decoration: none;
  margin: 6px 0;
  font-size: 0.5rem;
  @media (width > 400px) {
    font-size: 0.7rem;
    margin: 9px 0;
  }
  @media (width > 500px) {
    font-size: 0.9rem;
    margin: 12px 0;
  }
}